import { Routes, Route, useLocation } from "react-router-dom";

import Landing from "../pages/landing/Landing";
import About from "../pages/about/About";
import Projects from "../pages/projects/Projects";
import Contact from "../pages/contact/Contact";
import BugBouncer from "../pages/bug-bouncer/BugBouncer";
import LeonGPT from "../pages/leon-gpt/LeonGPT";

const AnimatedRoutes = ({ personalDetails }) => {
  const location = useLocation();
  console.log(location)


  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline}  age = {personalDetails.age}/>} />
           <Route path="/tr3x.xyz/" element={<Landing name={personalDetails.name} tagline={personalDetails.tagline}  age = {personalDetails.age}/>} />
      <Route path="/leon-gpt" element={<LeonGPT />} />
        <Route
        path="/about"
        element={
          <About
            name={personalDetails.name}
            age = {personalDetails.age}
            location={personalDetails.location}
            email={personalDetails.email}
            availability={personalDetails.availability}
            brand={personalDetails.brand}
          />
        }
      />
      <Route path="/bug-bouncer" element={<BugBouncer />} />
      <Route path="/projects" element={<Projects />} />
      <Route
        path="/contact"
        element={
          <Contact name={personalDetails.name} location={personalDetails.location} email={personalDetails.email} />
        }
      />
    </Routes>
  );
};

export default AnimatedRoutes;
