import React from 'react';
import Typewriter from "typewriter-effect";
import SocialIcons from "../../components/SocialIcons";
import ChatBot from "../../components/ChatBot";
import "../../styles/landing.css";

const Landing = ({ name, age }) => {
    return (
        <section className="landing">
          
            <div className="textContainer">
                <h1 className="name">
                    {name}
                </h1>
                <div className="description">
                    <Typewriter
                        options={{
                            loop: true,
                            deleteSpeed: 100, 
                            delay: 100, 
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString("Welcome to my Portfolio!")
                                .pauseFor(1500)
                                .deleteAll()
                                .typeString(`I'm a ${age} year old computer science student & software developer.`)
                                .pauseFor(1500)
                                .deleteAll()
                                .typeString("Feel free to chat with me using LeonGPT.")
                                .pauseFor(1500)
                                .deleteAll()
                                .typeString("Discover projects, from web development to machine learning.")
                                .pauseFor(1500)
                                .deleteAll()
                                .typeString("Engage with my journey through code and creativity.")
                                .pauseFor(1500)
                                .deleteAll()
                                .start();
                        }}
                    />
                </div>

                <ChatBot />

            </div>

            <div className="socialIconsLanding">
                <SocialIcons />
            </div>
        </section>
    );
};

export default Landing;
