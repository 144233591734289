import React, { useEffect, useState } from "react";
import SocialIcons from "../../components/SocialIcons";
import '../../styles/projects.css';
import leftArrow from '../../images/left-arrow.png';
import rightArrow from '../../images/right-arrow.png';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchProjects = async () => {
            try {
                const response = await fetch(`https://api.github.com/users/leonburghardtdev/repos?type=public&sort=updated`);
                const repos = await response.json();
                const projectData = repos.map(repo => ({
                    id: repo.id,
                    name: repo.name,
                    lastUpdated: new Date(repo.updated_at).toLocaleDateString("en-US"),
                    link: repo.html_url,
                    description: repo.description,
                    type: 'github'
                }));

                const manualProjects = [
                    {
                        id: 'gp-1',
                        name: "Bundesliga360 GooglePlay App",
                        lastUpdated: "08/31/2023",
                        link: "https://play.google.com/store/apps/details?id=xyz.tr3x.bundesliga360",
                        type: 'googleplay'
                    },
                    {
                        id: 'gp-2',
                        name: "TaskTarea GooglePlay App",
                        lastUpdated: "30/12/2022",
                        link: "https://play.google.com/store/apps/details?id=xyz.tr3x.TaskTarea",
                        type: 'googleplay'
                    },
                    {
                        id: 'gp-3',
                        name: "StackCO GooglePlay App",
                        lastUpdated: "08/04/2022",
                        link: "https://play.google.com/store/apps/details?id=com.tr3xTech.StackCO",
                        type: 'googleplay'
                    }
                ];

                const combinedProjects = [...projectData, ...manualProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setProjects(combinedProjects);
            } catch (error) {
                console.error("Failed to fetch projects", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProjects();
    }, []);

    const handleNext = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    };

    const handlePrev = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
    };

    return (
        <div className="portfolio">
            <h3 className="pageTitle">Projects</h3>
            <div className="projects-carousel">
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <div className="carousel-wrapper">
                        <button className="carousel-btn left-btn" onClick={handlePrev}>
                            <img src={leftArrow} alt="Previous" />
                        </button>
                        <div className="project-card">
                            <h4>{projects[currentProjectIndex]?.name}</h4>
                            <p>{projects[currentProjectIndex]?.description || "No description available."}</p>
                            <p className="last-updated">Last Updated: {projects[currentProjectIndex]?.lastUpdated}</p>
                            <a href={projects[currentProjectIndex]?.link} className="project-link" target="_blank" rel="noopener noreferrer">
                                More
                            </a>
                        </div>
                        <button className="carousel-btn right-btn" onClick={handleNext}>
                            <img src={rightArrow} alt="Next" />
                        </button>
                    </div>
                )}
                <div className="mobile-navigation">
                    <button className="mobile-nav-btn left-btn" onClick={handlePrev}>
                        <img src={leftArrow} alt="Previous" />
                    </button>
                    <button className="mobile-nav-btn right-btn" onClick={handleNext}>
                        <img src={rightArrow} alt="Next" />
                    </button>
                </div>
            </div>
            <div className="pagination-dots">
                {projects.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentProjectIndex ? 'active' : ''}`}
                        onClick={() => setCurrentProjectIndex(index)}
                    ></span>
                ))}
            </div>
            <SocialIcons />
            
        </div>
    );
};

export default Projects;
