import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../../styles/leon-gpt.css';
import PageHeader from "../../components/PageHeader";
import userIcon from '../../images/user-chat.png';  // Replace with your user icon path
import botIcon from '../../images/ai-chat.png';    // Replace with your bot icon path

const LeonGPT = () => {
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const chatWindowRef = useRef(null); 

    useEffect(() => {
        const welcomeMessage = { role: 'bot', content: 'Hi i\'m Leon. Ask me anything you would like to know about me!' };
        setMessages([welcomeMessage]);
    }, []);

    const handleSend = async () => {
        if (inputText.trim() === '') return;
        if (isLoading) return;

        const userMessage = { role: 'user', content: inputText };
        setMessages([...messages, userMessage]);
        setInputText('');
        setIsLoading(true);

        try {
            const response = await axios.post(process.env.API_URL+'/generate', {
                input_text: inputText,
            });
            const botMessage = { role: 'bot', content: response.data.response };
            setMessages([...messages, userMessage, botMessage]);
        } catch (error) {
            const errorMessage = { role: 'bot', content: 'Something went wrong. Please try again.' };
            setMessages([...messages, userMessage, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="leon-gpt-container">
            <PageHeader title="LeonGPT" description="" />
            <p className="gpt-description" >An artificial intelligence built on OpenAI's GPT-2 117M model, trained specifically to provide information about me, demonstrating my skills in artificial intelligence.</p>
            <div className="chat-window" ref={chatWindowRef}>
                {messages.map((msg, index) => (
                    <div key={index} className={`message-container ${msg.role}`}>
                        <img 
                            src={msg.role === 'user' ? userIcon : botIcon} 
                            alt={`${msg.role} icon`} 
                            className={msg.role === 'user' ? "message-icon-user" : "message-icon-bot"}
                        />
                        <div className={`message ${msg.role}`}>
                            <p>{msg.content}</p>
                        </div>
                    </div>
                ))}
                {isLoading && <div className="loading-circle"></div>}
            </div>
    
            {/* Desktop input bar */}
            <div className="input-bar-desktop">
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Type your message..."
                    className="chatbot-input"
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend} className="chatbot-send-btn" disabled={isLoading}>
                    Send
                </button>
            </div>
    
            {/* Mobile input bar */}
            <div className="input-bar-mobile">
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Type your message..."
                    className="chatbot-input-mobile"
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend} className="chatbot-send-btn-mobile" disabled={isLoading}>
                    Send
                </button>
            </div>
        </div>
    );
};
    
export default LeonGPT;
