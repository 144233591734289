import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  let birthDate = new Date("2004-05-14");
  let today = new Date();
  let ageInMilliseconds = today.getTime() - birthDate.getTime();
  let ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
  let age = Math.floor(ageInYears).toString();

  const personalDetails = {
    name: "Leon Burghardt",
    age: age,
    location: "near Dortmund, Germany",
    email: "contact@leon-burghardt.dev",
    availability: "Open for part time opportunities",
    brand:
    "“The computer was born to solve problems that did not exist before,” said Bill Gates, founder and former CEO of Microsoft. In this spirit, I navigate the evolving landscape of technology, where my academic endeavors in computer science and my passion projects in software development and artificial intelligence converge to address tomorrow’s challenges today."
      };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
