import { motion } from "framer-motion";
import SocialIcons from "../components/SocialIcons";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";

const AboutMe = ({ name, email, location, age, brand }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  return (
    <div className="aboutContainer container d-flex justify-content-center align-items-center" style={{ minHeight: "10vh" }}>
      <div className="row w-100 d-flex justify-content-center">
        <motion.div
          className="personalInfo col-12 col-lg-8"
          ref={ref}
          animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="contentContainer text-center">
            <h5>Coding for a Better Tomorrow: Be a part of my journey!</h5>
            <p style={{ color: '#8e8e8e' }}>{brand}</p>

            <div className="infoContainer">
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>Name</span>
                  <p>{name}</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Age</span>
                  <p>{age}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>Location</span>
                  <p>{location}</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Email</span>
                  <p>
                    <a href={`mailto:${email}`}>{email}</a>
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div className="buttonContainer">
              <br />
              <div className="desktopSocialIcons">
                <SocialIcons />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutMe;
