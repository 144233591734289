import React, { useEffect, useRef, useState } from 'react';
import '../../styles/bug-bouncer.css';
import PageHeader from "../../components/PageHeader";

const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; SameSite=Lax`;
};


const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '');
};


const BugBouncer = () => {
    const gameRef = useRef(null);
    const [player, setPlayer] = useState({ x: 150, y: 400 });
    const velocity = useRef({ x: 0, y: -8 });
    const [platforms, setPlatforms] = useState([]);
    const [cameraOffset, setCameraOffset] = useState(0);
    const [sessionId, setSessionId] = useState(''); 
    const [targetCameraOffset, setTargetCameraOffset] = useState(0);
    const [lastPlayerY, setLastPlayerY] = useState(400);
    const [highScore, setHighScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [nickname, setNickname] = useState(''); 
    const [showLeaderboardManually, setShowLeaderboardManually] = useState(false); 
    const [lastPlatformY, setLastPlatformY] = useState(null); 
    const [isMobileView, setIsMobileView] = useState(false);
    const [activeMobileView, setActiveMobileView] = useState('start');
    const gravity = 0.2;
    const totalPlatforms = 250;
    const gameWidth = 300;

    useEffect(() => {
        const checkDeviceSize = () => {
            const width = window.innerWidth;
            if (width < 600) { 
                setIsMobileView(true);
            } else {
                setIsMobileView(false);
            }
        };

        checkDeviceSize();
        window.addEventListener('resize', checkDeviceSize);

        return () => {
            window.removeEventListener('resize', checkDeviceSize);
        };
    }, []);



    useEffect(() => {
        // Check if session ID exists in cookies
        const savedSessionId = getCookie('session_id');
        const savedNickname = getCookie('nickname');
        if (savedSessionId) {
            setSessionId(savedSessionId);  
            setNickname(savedNickname);  
            setGameStarted(false);  
        }
    }, []);
    
    useEffect(() => {
        if (gameStarted) {
            fetch(process.env.API_URL+'/leaderboard')
                .then(response => response.json())
                .then(data => setLeaderboard(data))
                .catch(error => console.error('Error fetching leaderboard:', error));
        }
    }, [gameStarted]);

    const generatePlatforms = (numberOfPlatforms = 250) => {
        const newPlatforms = [];
        let lastPlatformY = 500;
        for (let i = 0; i < numberOfPlatforms; i++) {
            const platformX = Math.random() * 240;
            const platformY = lastPlatformY - Math.random() * 80 - 50;
            newPlatforms.push({ x: platformX, y: platformY });
            lastPlatformY = platformY;
        }
        return newPlatforms;
    };

    const resetGame = () => {
        setPlayer({ x: 150, y: 400 });
        velocity.current = { x: 0, y: -8 };
        setCameraOffset(0);
        setTargetCameraOffset(0);
        setLastPlayerY(400);
        setHighScore(0);
        setGameOver(false);
        const initialPlatforms = generatePlatforms(totalPlatforms);
        setPlatforms(initialPlatforms);
    };

    useEffect(() => {
        resetGame();
    }, []);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'ArrowLeft') {
                velocity.current.x = -3;
            } else if (e.key === 'ArrowRight') {
                velocity.current.x = 3;
            }
        };

        const handleKeyUp = (e) => {
            if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                velocity.current.x = 0;
            }
        };

        const handleDeviceOrientation = (event) => {
            const tilt = event.gamma;
            const maxTilt = 45;
            const speedFactor = 3 / maxTilt;
            velocity.current.x = tilt * speedFactor;
        };

        document.addEventListener('keydown', handleKeyPress);
        document.addEventListener('keyup', handleKeyUp);
        window.addEventListener('deviceorientation', handleDeviceOrientation);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('deviceorientation', handleDeviceOrientation);
        };
    }, []);

    useEffect(() => {
        const gameLoop = setInterval(() => {
            if (!gameStarted || gameOver) return; 

            setPlayer((p) => {
                const newVelocityY = velocity.current.y + gravity;
                const newY = p.y + newVelocityY;
                let newX = p.x + velocity.current.x;

                if (newX < 0) {
                    newX = gameWidth - 20;
                } else if (newX + 20 > gameWidth) {
                    newX = 0;
                }

                if (newY > 600) {
                    setGameOver(true);
                    sendHighScore();
                }

                let onPlatform = false;
                let collidedPlatform = null;

                platforms.forEach((platform) => {
                    if (
                        newX + 20 > platform.x &&
                        newX < platform.x + 60 &&
                        p.y + 20 <= platform.y &&
                        p.y + 20 + newVelocityY > platform.y &&
                        velocity.current.y > 0
                    ) {
                        onPlatform = true;
                        collidedPlatform = platform;
                    }
                });

                if (onPlatform && collidedPlatform) {
                    velocity.current.y = -8;

                    if (lastPlatformY !== collidedPlatform.y) {
                        setHighScore((prevScore) => prevScore + 1);
                        setLastPlatformY(collidedPlatform.y);
                    }

                    const heightDifference = lastPlayerY - collidedPlatform.y;
                    if (collidedPlatform.y < lastPlayerY) {
                        setTargetCameraOffset((prevOffset) => prevOffset + heightDifference);
                        setLastPlayerY(collidedPlatform.y);
                    }

                    return { ...p, x: newX, y: collidedPlatform.y - 20 };
                }
                if (newY < 200) { 
                    const differenceToCenter = 200 - newY;
                    setTargetCameraOffset(prevOffset => prevOffset + differenceToCenter);
                }

                return {
                    x: newX,
                    y: newY,
                };
            });

            velocity.current.y += gravity;

            const smoothMoveSpeed = 2;
            if (cameraOffset < targetCameraOffset) {
                setCameraOffset((offset) => Math.min(offset + smoothMoveSpeed, targetCameraOffset));
            }

            if (cameraOffset < targetCameraOffset) {
                const offsetChange = smoothMoveSpeed;
                setPlayer((p) => ({
                    ...p,
                    y: p.y + offsetChange,
                }));

                setPlatforms((ps) =>
                    ps.map((platform) => ({
                        ...platform,
                        y: platform.y + offsetChange,
                    }))
                );
            }

            setPlatforms((ps) =>
                ps.map((platform) => {
                    if (platform.y > 600) {
                        return {
                            ...platform,
                            y: platform.y - 250 * 80,
                        };
                    }
                    return platform;
                })
            );
        }, 20);

        return () => clearInterval(gameLoop);
    }, [platforms, cameraOffset, targetCameraOffset, lastPlayerY, highScore, gameStarted, gameOver]);

    const handleStartGame = () => {
        if (nickname.trim() === '') return;
        
        fetch(process.env.API_URL+'/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ nickname: nickname })
        })
        .then(response => response.json())
        .then(data => {
            setSessionId(data.session_id); 
            setCookie('session_id', data.session_id, 365);  
            setCookie('nickname', nickname, 365);  
            setGameStarted(true);
        })
        .catch(error => console.error('Error starting game:', error));
    };
    

    const fetchLeaderboard = () => {
        fetch(process.env.API_URL+'/leaderboard')
            .then(response => response.json())
            .then(data => setLeaderboard(data))
            .catch(error => console.error('Error fetching leaderboard:', error));
    };


    const sendHighScore = () => {
        if (!sessionId) return;
        

        fetch(process.env.API_URL+'/leaderboard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: sessionId,  
                player_name: nickname,  
                score: highScore        
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to send high score');
            }
            return response.json();
        })
        .then(() => {
            fetchLeaderboard();
        })
        .catch(error => console.error('Error sending high score:', error));
    };
    
    
    const handleShowLeaderboardManually = () => {
        if (showLeaderboardManually) {
            setShowLeaderboardManually(false);
            return;
        }
        setShowLeaderboardManually(true);
        fetchLeaderboard();
    }
    

    return (
    <section className='game'>
    <PageHeader title="Bug Bouncer" description="" />
    <p className="game-description">A small web-based version of the popular game "Doodle Jump", built with React.js to showcase my web development skills.</p>
   
    {!isMobileView ? (
        <div className="game-wrapper">
            {gameOver || showLeaderboardManually && !gameStarted ? (
                <div className="leaderboard">
                    <h2>Leaderboard</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Player</th>
                                <th>High Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboard.map((entry, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{entry.player_name}</td>
                                    <td>{entry.score}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
            <div className="game-container" ref={gameRef}>
                {!gameStarted ? (
                    <div className="start-screen">
                        <h1>Jump In!</h1>
                        <input
                            type="text"
                            placeholder="Enter your nickname"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            maxLength={7}
                        />
                        <button onClick={handleStartGame} disabled={!nickname.trim()}>Start Game</button>
                        {!showLeaderboardManually ? (
                            <button onClick={handleShowLeaderboardManually}>Show Leaderboard</button>
                        ) : (
                            <button onClick={handleShowLeaderboardManually}>Hide Leaderboard</button>
                        )}
                    </div>
                ) : gameOver ? (
                    <div className="game-over">
                        <h1>Game Over</h1>
                        <p>Highscore: {highScore}</p>
                        <button onClick={resetGame}>Play Again</button>
                    </div>
                ) : (
                    <>
                        <div className="player" style={{ left: `${player.x}px`, top: `${player.y}px` }}></div>
                        {platforms.map((platform, index) => (
                            <div key={index} className="platform" style={{ left: `${platform.x}px`, top: `${platform.y}px` }}></div>
                        ))}
                        <div className="highscore">Highscore: {highScore}</div>
                    </>
                )}
            </div>
        </div>
    ) : (
        
        <div className="mobile-game">
                <div className="mobile-content">
                    {!gameStarted && !showLeaderboardManually ? (
                        <div className="start-screen">
                            <h1>Jump In!</h1>
                            <input
                                type="text"
                                placeholder="Enter your nickname"
                                value={nickname}
                                onChange={(e) => setNickname(e.target.value)}
                                maxLength={7}
                            />
                            <button onClick={handleStartGame} disabled={!nickname.trim()}>Start Game</button>
                            {!showLeaderboardManually ? (
                            <button onClick={handleShowLeaderboardManually}>Show Leaderboard</button>
                        ) : (
                            <button onClick={handleShowLeaderboardManually}>Hide Leaderboard</button>
                        )}
                        </div>
                            ) : gameOver && !showLeaderboardManually ? (
                                <>
                                <div className="game-over">
                                    <h1>Game Over</h1>
                                    <p>Highscore: {highScore}</p>
                                    <button onClick={resetGame}>Play Again</button>
                                </div>
                                </>
                            ) : !showLeaderboardManually && (
                                <>
                                    <div className="mobile-game-container">
                                        <div className="game-content">
                                            <div className="player" style={{ left: `${player.x}px`, top: `${player.y}px` }}></div>
                                            {platforms.map((platform, index) => (
                                                <div key={index} className="platform" style={{ left: `${platform.x}px`, top: `${platform.y}px` }}></div>
                                            ))}
                                            
                                        <div className="highscore">Highscore: {highScore}</div>
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                    {showLeaderboardManually && (
                        <>
                        <div className="mobile-leaderboard">
                            <h2>Leaderboard</h2>
                            <div className="scrollable-leaderboard">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Player</th>
                                            <th>High Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaderboard.map((entry, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{entry.player_name}</td>
                                                <td>{entry.score}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>

                        <button className="back-button" onClick={() => setShowLeaderboardManually(false)}>Back</button>
                        </>
                    )}              
                </div>
    )}
</section>

    );
};
export default BugBouncer;
