import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ContactInfo = ({ name, email, location }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <motion.div
      className="contactInfo"
      ref={ref}
      animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <h4 className="contentTitle">Contact Information</h4>
        <p className="infoDescription">You can reach me through the linked social accounts or by sending an email.<br></br> I will respond to your inquiry as quickly as possible.</p>
      <ul className="listInfo">
        <li>
              <h6 className="infoType">&nbsp;</h6>
              <span className="infoValue">
          <a href={`mailto:${email}`}>{email}</a>
        </span>
        </li>
      </ul>

    </motion.div>
  );
};

export default ContactInfo;
