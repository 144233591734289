import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import '../styles/chatbot.css';  // Reusing the styles from LeonGPT
import userIcon from '../images/user-chat.png';  // Reuse or replace with your user icon path
import botIcon from '../images/ai-chat.png';    // Reuse or replace with your bot icon path

const ChatBot = () => {
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState([]); // Reuse the messages array for both user and bot messages
    const [isLoading, setIsLoading] = useState(false);
    const [wasAsked, setWasAsked] = useState(false);

    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleSend = async () => {
        if (inputText.trim() === '') return;

        setIsLoading(true);
        if (!wasAsked) {
            setWasAsked(true);
        }

        const userMessage = { role: 'user', content: inputText };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setInputText('');

        try {
            const response = await axios.post("https://leon-burghardt.dev:5000/generate", {
                input_text: inputText,
            });
            const botMessage = { role: 'bot', content: response.data.response };

            // Add both the response message and the static message together
            const staticMessage = { role: 'bot', content: 'Click the button below to chat more with me!' };
            setMessages(prevMessages => [...prevMessages, botMessage, staticMessage]);

        } catch (error) {
            const errorMessage = { role: 'bot', content: 'Something went wrong. Please try again.' };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

   const handleChatWithMe = () => {
       navigate('/leon-gpt'); // Navigate to the LeonGPT page
    }

    return (
        <div className="leon-gpt-container">
            <div className="chat-window">
                <h2 className="chatbot-title">Chat with LeonGPT !</h2>
                {!wasAsked && !isLoading && (
                    <div className={`input-bar${isLoading ? ' loading' : ''}`}>
                        <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Ask anything about me..."
                            className="chatbot-input"
                            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                        />
                        <button onClick={handleSend} className='chatbot-send-btn' disabled={isLoading}>
                            Send
                        </button>
                    </div>
                )}
                {messages.map((msg, index) => (
                    <div key={index} className={`message-container ${msg.role}`}>
                        <img 
                            src={msg.role === 'user' ? userIcon : botIcon} 
                            alt={`${msg.role} icon`} 
                            className={msg.role === 'user' ? "message-icon-user" : "message-icon-bot"}
                        />
                        <div className={`message ${msg.role}`}>
                            <p>{msg.content}</p>
                        </div>
                    </div>
                ))}
                {wasAsked && !isLoading && (
                    <button onClick={handleChatWithMe} className='chatbot-chat-with-me'>
                        Chat with me!
                    </button>
                )}
                {isLoading && (
                    <div className="loading-circle"></div>
                )}
            </div>
        </div>
    );
};

export default ChatBot;
