import React from "react";
import "../styles/experiences.css";

const Experiences = () => {
    const experiences = [
        {
            year: "since 2024",
            ongoing: true,
            title: "Software Developer",
            company: "Kleen Software GmbH",
            description: "Working in Software Development since February 2024 for ",
            link: "https://www.kleen-software.de/"
        },
        {
            year: "2023 - 2026",
            ongoing: true,
            title: "Bachelor of Science in Computer Science",
            company: "Technical University Dortmund",
            description: "Studying Computer Science since October 2023 at the ",
            link: "https://www.tu-dortmund.de/"
        },
        {
            year: "2010 - 2023",
            ongoing: false,
            title: "High School Diploma",
            company: "",
            description: "High School with specializations in Mathematics and Computer Science."
        },
        // Fügen Sie hier weitere Erfahrungen hinzu
    ];

    return (
        <>
        <h2>Career</h2>
        <div className="roadmap-container">
           
            {experiences.map((exp, index) => (
                <div key={index} className="roadmap-item">
                    <div className="roadmap-step-details">
                        <span>
                            {exp.year} {exp.ongoing && <span className="ongoing">Ongoing</span>}
                        </span>
                        <h3>{exp.title}</h3>
                        <p>
                            {exp.description}
                            {exp.link ? (
                                <a id="comp_link" href={exp.link} target="_blank" rel="noopener noreferrer">
                                    {exp.company}
                                </a>
                            ) : exp.company}
                        </p>
                    </div>
                </div>
            ))}
        </div>
        </>
    );
};

export default Experiences;
